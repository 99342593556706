<template>
  <container ref="container" class="understand" @error="init">
    <div class="understand-content" v-html="detail.content"></div>
  </container>
</template>

<script>
export default {
  name: "postpartumUnderstand",
  components: {},
  data() {
    return {
      detail: "",
    };
  },
  mounted() {
    // 初始化
    this.init();
  },
  methods: {
    /**
     * 初始化数据
     */
    async init() {
      try {
        // 设置状态
        this.$refs.container.status("init", {
          height: "100vh",
        });

        // 获取数据
        const detail = await this.$axios("/api/mainstation/demystify", {
          params: {
            type: 3,
          },
        });

        // 设置数据
        this.detail = detail.data;

        // 设置状态
        this.$refs.container.status("default");
      } catch (e) {
        // 设置状态
        this.$refs.container.status("error", {
          text: e.message,
          height: "100vh",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  .understand {
    &-content {
      width: 1200px;
      margin: 90px auto;
      display: block;
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .understand {
    &-content {
      width: 100%;
      margin: rpx(90) auto;
      display: block;
    }
  }
}
</style>
